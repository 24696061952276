
import CheckoutCard from "./CheckoutCard";

export default function GridCheckout({ getCheckouts, dashboardRequest, userInfo }) {

    return (
        <div className='w-full mt-6'>
            <div className="grid grid-flow-row auto-rows-max gap-6 lg:grid-cols-3">
                {
                    getCheckouts.data.map((card, index) =>
                        <CheckoutCard 
                            key={index} 
                            card={card} 
                            dashboardRequest={dashboardRequest}
                            userInfo={userInfo} />
                    )
                }
            </div>

        </div>
    );
}