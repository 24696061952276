
import { useTypeKey } from '../../hooks/useTypeKey';

export default function FilterImmexPartnumbers(props) {
    const { handleForm, setPn, pn, handleRequest } = props;


    const typeKeyHandle = () => {
        handleForm(false);
    }

    useTypeKey(typeKeyHandle, "Escape");
    return (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex items-center justify-center z-50 overflow-hidden p-4">
            <div className="bg-white p-6 rounded-lg shadow-lg relative w-full md:w-4/12 max-h-full overflow-y-auto">
                <h3 className="font-bold text-lg mb-4">Filter Search</h3>
                <button type='button' className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2 " onClick={() => handleForm(false)}>✕</button>
                <form onSubmit={e => { e.preventDefault(); }}>
                <div className="mb-4">
                <label className="input input-bordered flex items-center ">
                    <input
                        onChange={(e) => setPn(e.target.value)}
                        value={pn}
                        type="text"
                        className="grow"
                        placeholder="Search" />
                </label>
                </div>

                <button
                    onClick={() => handleRequest()}
                    className="w-full bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded rounded-lg focus:outline-none focus:shadow-outline">
                    Search
                </button>
                </form>
            </div>
        </div>

    );
};

