export default function BarImmexCheckout({refreshPage}) {
    return (
        <div className="navbar bg-base-100 my-4 rounded-xl ">
            <div className="flex-1">
                <a className="btn btn-ghost text-xl"></a>
            </div>
            <div className="navbar-end">
                <button
                    onClick={()=>refreshPage()}
                    className="btn btn-square btn-info ml-4">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M17.651 7.65a7.131 7.131 0 0 0-12.68 3.15M18.001 4v4h-4m-7.652 8.35a7.13 7.13 0 0 0 12.68-3.15M6 20v-4h4" />
                    </svg>
                </button>
            </div>
        </div>
    );
}