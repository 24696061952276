import ImmexPartnumbersCard from "./ImmexPartnumbersCard";

export default function GridImmexPartnumbers({ getPartnumbers, userInfo }) {

    return (
        <div className='w-full mt-6'>
            <div className="grid grid-flow-row auto-rows-max gap-6 lg:grid-cols-3">
                {
                    getPartnumbers.data.map((card, index) =>
                        <ImmexPartnumbersCard 
                            key={index} 
                            card={card} 
                            dashboardRequest={getPartnumbers.handleRequest}
                            userInfo={userInfo} />
                    )
                }
            </div>

        </div>
    );
}