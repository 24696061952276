import { useState, useRef } from "react";
import PedimentoTable from "../../components/tables/PedimentoTable";
import PedimentoPartnumberTable from "../../components/tables/PedimentoPartnumberTable";
import * as XLSX from 'xlsx';
import useFetchClick from "../../hooks/useFetchClick";
import useFetch from "../../hooks/useFetch";

const getSumList = (data, key) => {
    const bomCost = data.reduce((total, row) => {
        return total + parseFloat(row[key])
    }, 0)

    return bomCost;
}

const columnsFile = [
    "pedimento",
    "import_date",
    "qty",
    "metric_unit",
    "partnumber",
    "description",
    "unit_price",
    "hts"
]

export default function ImmexPedimento() {
    const [pedimentosOrParts, setPedimentosOrParts] = useState(true);
    const [pedimentoId, setPedimentoId] = useState(0);
    const [data, setData] = useState([]);
    const [errorFile, setErrorFile] = useState("");
    const [refresh, setRefresh] = useState(false);
    const [pn, setPn] = useState("");
    const [pedimento, setPedimento] = useState(null);

    const successAddFile = () => {
        setRefresh(!refresh);
    }
    const addPedimentosFile = useFetchClick(
        `${process.env.REACT_APP_API_URL}add_pedimentos_list_file`,
        { content: data }, successAddFile);

    const pedimentosTable = useFetch(`${process.env.REACT_APP_API_URL}get_pedimentos`, { pedimento: pedimento });
    const partnumbersTable = useFetch(`${process.env.REACT_APP_API_URL}get_pedimentos_partnumber`,
        { id: pedimentoId, partnumber: pn });

    const handleFileUpload = (event) => {
        const file = event.target.files[0];  // Get the uploaded file
        if (!file) return;  // If no file is selected, exit the function
        const reader = new FileReader();

        reader.onload = (e) => {
            const data = new Uint8Array(e.target.result);

            const workbook = XLSX.read(data, { type: 'array' });
            // Get the first sheet
            const firstSheet = workbook.Sheets[workbook.SheetNames[0]];
            console.log(firstSheet)
            // Convert the sheet to JSON
            const jsonData = XLSX.utils.sheet_to_json(firstSheet, { header: 1, raw: false});
            console.log(jsonData)
            // Transform the 2D array into a list of objects (optional)
            const headers = jsonData[1];
            if (headers.length) {
                const checkColumns = columnsFile.map((column) => headers.includes(column))
                if (checkColumns.includes(false)) {
                    setErrorFile(`Columns Missing:${headers}`)
                }
                else {
                    if (jsonData.length > 3) {
                        const rows = jsonData.slice(2).map((row) => {
                            let obj = {};
                            headers.forEach((header, index) => {
                                obj[header] = row[index];
                            });
                            return obj;
                        });
                        // Set the data into state
                        setData(rows);
                        setErrorFile("")
                    }
                    else {
                        setErrorFile("No rows")
                    }
                }
            }
            else {
                setErrorFile(`No Columns Detected`)
            }
        };

        reader.readAsArrayBuffer(file);
    };


    const changeTableById = (id, sta) => {
        setPedimentoId(id);
        setPn("");
        setPedimentosOrParts(sta);
    }


    const inputFile = useRef(null);

    // Function to reset the input element
    const handleReset = () => {
        if (inputFile.current) {
            inputFile.current.value = "";
        }
    };
    const searchClick = () => {
        if (pedimentosOrParts) {
            pedimentosTable.handleRequest();
        }
        else {
            partnumbersTable.handleRequest();
        }
    }
    const searchType = (e) => {
        if (pedimentosOrParts) {
            setPedimento(e.target.value);
        }
        else {
            setPn(e.target.value);
        }
    }
    console.log(pedimentosTable.data)
    return (
        <div className="w-full">
                        <div className="stats stats-vertical lg:stats-horizontal shadow">
                        <div className="stat">
                    <div className="stat-title">Stock Price</div>
                    <div className="stat-value">{getSumList(pedimentosTable.data, "current_price")}</div>
                </div>
                <div className="stat">
                    <div className="stat-title">Stock Qty</div>
                    <div className="stat-value">{getSumList(pedimentosTable.data, "current_qty")}</div>
                </div>
                <div className="stat">
                    <div className="stat-title">Package Price</div>
                    <div className="stat-value">{getSumList(pedimentosTable.data, "package_price")}</div>
                </div>
                <div className="stat">
                    <div className="stat-title">Package Qty</div>
                    <div className="stat-value">{getSumList(pedimentosTable.data, "package_qty")}</div>
                </div>

                <div className="stat">
                    <div className="stat-title">Partnumbers</div>
                    <div className="stat-value">{getSumList(pedimentosTable.data, "partnumbers")}</div>
                </div>

                <div className="stat">
                    <div className="stat-title">Pedimentos</div>
                    <div className="stat-value">{pedimentosTable.data.length}</div>
                </div>
            </div>
            <div className="py-2 flex">
                <input
                    accept=".xlsx, .xls"
                    onChange={handleFileUpload}
                    ref={inputFile}
                    onClick={handleReset}
                    type="file"
                    className="file-input file-input-sm file-input-bordered file-input-info w-full max-w-xs mr-2" />
                {
                    data.length ?
                        <button
                            onClick={() => addPedimentosFile.handleRequest()}
                            className="btn-sm btn btn-success mr-2">
                            {
                                !addPedimentosFile.loading ?
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="h-6 w-6"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor">
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth="2"
                                            d="M12 5v9m-5 0H5a1 1 0 0 0-1 1v4a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1v-4a1 1 0 0 0-1-1h-2M8 9l4-5 4 5m1 8h.01" />
                                    </svg> :
                                    <span className="loading loading-spinner"></span>
                            }

                            UPLOAD
                        </button> :
                        null
                }
                <div className="grow" />
                <label className="input input-sm input-bordered flex items-center gap-2 mr-2">
                    <input
                        onChange={searchType}
                        value={pedimentosOrParts ? pedimento : pn}
                        type={pedimentosOrParts ? "number" : "text"}
                        className="input-xs grow"
                        placeholder="Search" />
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 16 16"
                        fill="currentColor"
                        className="h-4 w-4 opacity-70">
                        <path
                            fillRule="evenodd"
                            d="M9.965 11.026a5 5 0 1 1 1.06-1.06l2.755 2.754a.75.75 0 1 1-1.06 1.06l-2.755-2.754ZM10.5 7a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0Z"
                            clipRule="evenodd" />
                    </svg>
                </label>
                <button
                    onClick={() => searchClick()}
                    className="btn-sm btn btn-outline btn-info mr-2">
                    Search
                </button>
                <button
                    onClick={() => searchClick()}
                    className="btn-sm btn btn-outline btn-info mr-2">
                    Refresh
                </button>
                <button
                    onClick={() => changeTableById(0, !pedimentosOrParts)}
                    className="btn-sm btn btn-outline btn-neutral">
                    Change Table
                </button>
            </div>
            {errorFile ?
                <div role="alert" className="alert alert-warning">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-6 w-6 shrink-0 stroke-current"
                        fill="none"
                        viewBox="0 0 24 24">
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
                    </svg>
                    <span>{errorFile}</span>
                </div> : null
            }
            {
                addPedimentosFile.error ?
                    <div role="alert" className="alert alert-error">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-6 w-6 shrink-0 stroke-current"
                            fill="none"
                            viewBox="0 0 24 24">
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>
                        <span>{addPedimentosFile.error}</span>
                    </div> : null
            }
            {
                pedimentosOrParts ?
                    <PedimentoTable
                        changeTableById={changeTableById}
                        refresh={refresh}
                        pedimentosTable={pedimentosTable} /> :
                    <PedimentoPartnumberTable
                        partnumbersTable={partnumbersTable}
                        refresh={refresh} />
            }
        </div>
    );
}