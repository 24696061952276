import { useState } from "react"
import AddAndUpdateImmexPartnumber from "../form/AddAndUpdateImmexPartnumber";
import PartViewer from "../form/PartViewer";
import DeleteCard from "../form/DeleteCard";
import AddImmexCheckout from "../form/AddImmexCheckout";

export default function ImmexPartnumbersCard({ card, dashboardRequest, userInfo }) {
    const [showEdit, setShowEdit] = useState(false);
    const [showImage, setShowImage] = useState(false);
    const [showDelete, setShowDelete] = useState(false);
    const [showCheckout, setShowCheckout] = useState(false);
    return (
        <div className="w-full">
            <div className={!parseInt(card.available_qty)?`flex bg-red-500 rounded-lg drop-shadow-lg z-0`:`flex bg-green-500 rounded-lg drop-shadow-lg z-0`}>
                <div className="flex-none w-5" />
                <div className="gcard bg-base-100  p-4 w-full">
                    <div className="text-lg lg:text-2xl text-center ">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" className="inline-block w-6 h-6 stroke-current mr-1 mb-1 text-white bg-fuchsia-600 rounded-md"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 10V6a3 3 0 0 1 3-3v0a3 3 0 0 1 3 3v4m3-2 .917 11.923A1 1 0 0 1 17.92 21H6.08a1 1 0 0 1-.997-1.077L6 8h12Z"></path></svg>

                        {card.partnumber}
                    </div>
                    <div className="flex flex-wrap py-2">
                        <div className="basis-1/2  text-base lg:text-xl">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" className="inline-block w-6 h-6 stroke-current mr-1 mb-1 text-white bg-green-600 rounded-md">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 13a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z"></path>
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M17.8 13.938h-.011a7 7 0 1 0-11.464.144h-.016l.14.171c.1.127.2.251.3.371L12 21l5.13-6.248c.194-.209.374-.429.54-.659l.13-.155ZZ"></path>
                            </svg>
                            {!card.location_tag?'None':card.location_tag}
                        </div>
                        <div className="basis-1/4  text-base lg:text-xl">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" className="inline-block w-6 h-6 stroke-current mr-1 mb-1 text-white bg-blue-600 rounded-md">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 12c.263 0 .524-.06.767-.175a2 2 0 0 0 .65-.491c.186-.21.333-.46.433-.734.1-.274.15-.568.15-.864a2.4 2.4 0 0 0 .586 1.591c.375.422.884.659 1.414.659.53 0 1.04-.237 1.414-.659A2.4 2.4 0 0 0 12 9.736a2.4 2.4 0 0 0 .586 1.591c.375.422.884.659 1.414.659.53 0 1.04-.237 1.414-.659A2.4 2.4 0 0 0 16 9.736c0 .295.052.588.152.861s.248.521.434.73a2 2 0 0 0 .649.488 1.809 1.809 0 0 0 1.53 0 2.03 2.03 0 0 0 .65-.488c.185-.209.332-.457.433-.73.1-.273.152-.566.152-.861 0-.974-1.108-3.85-1.618-5.121A.983.983 0 0 0 17.466 4H6.456a.986.986 0 0 0-.93.645C5.045 5.962 4 8.905 4 9.736c.023.59.241 1.148.611 1.567.37.418.865.667 1.389.697Zm0 0c.328 0 .651-.091.94-.266A2.1 2.1 0 0 0 7.66 11h.681a2.1 2.1 0 0 0 .718.734c.29.175.613.266.942.266.328 0 .651-.091.94-.266.29-.174.537-.427.719-.734h.681a2.1 2.1 0 0 0 .719.734c.289.175.612.266.94.266.329 0 .652-.091.942-.266.29-.174.536-.427.718-.734h.681c.183.307.43.56.719.734.29.174.613.266.941.266a1.819 1.819 0 0 0 1.06-.351M6 12a1.766 1.766 0 0 1-1.163-.476M5 12v7a1 1 0 0 0 1 1h2v-5h3v5h7a1 1 0 0 0 1-1v-7m-5 3v2h2v-2h-2Z"></path>
                            </svg>
                            {card.available_qty}
                        </div>
                        <div className="basis-1/4  text-base lg:text-xl">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" className="inline-block w-6 h-6 stroke-current mr-1 mb-1 text-white bg-gray-600 rounded-md">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 11h2v5m-2 0h4m-2.592-8.5h.01M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"></path>
                            </svg>
                            {card.metric_unit}
                        </div>
                    </div>
                    <div className="w-full bg-gray-800 p-2 rounded-lg text-white">
                        {card.description}
                    </div>
                    <div className="flex">

                        <button
                            disabled={card.file_name === null}
                            onClick={() => setShowImage(true)}
                            className="btn btn-square btn-success mr-4 mt-4">
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m3 16 5-7 6 6.5m6.5 2.5L16 13l-4.286 6M14 10h.01M4 19h16a1 1 0 0 0 1-1V6a1 1 0 0 0-1-1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1Z" /></svg>
                        </button>
                        <button
                            disabled={userInfo.userImmexRole !== "admin"}
                            onClick={() => setShowEdit(true)}
                            className="btn btn-square btn-info mr-4 mt-4">
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m14.304 4.844 2.852 2.852M7 7H4a1 1 0 0 0-1 1v10a1 1 0 0 0 1 1h11a1 1 0 0 0 1-1v-4.5m2.409-9.91a2.017 2.017 0 0 1 0 2.853l-6.844 6.844L8 14l.713-3.565 6.844-6.844a2.015 2.015 0 0 1 2.852 0Z" /></svg>
                        </button>
                        <button
                            disabled={card.available_qty==='0'}
                            onClick={() => setShowCheckout(true)}
                            className="btn btn-square btn-success mr-4 mt-4">
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 10V6a3 3 0 0 1 3-3v0a3 3 0 0 1 3 3v4m3-2 .917 11.923A1 1 0 0 1 17.92 21H6.08a1 1 0 0 1-.997-1.077L6 8h12Z" /></svg>
                        </button>
                        <button
                            disabled={userInfo.userImmexRole !== "admin"}
                            onClick={() => setShowDelete(true)}
                            className="btn btn-square btn-error mt-4">
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 7h14m-9 3v8m4-8v8M10 3h4a1 1 0 0 1 1 1v3H9V4a1 1 0 0 1 1-1ZM6 7h12v13a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V7Z" /></svg>
                        </button>
                        
                    </div>
                </div>

                <div className="flex-none w-5 " />
            </div>
            {
                            showEdit ?
                                <AddAndUpdateImmexPartnumber
                                    handleForm={setShowEdit}
                                    dashboardRequest={dashboardRequest}
                                    title="Update Partnumber"
                                    startBody={{
                                        partnumber_id: card.immex_partnumber_id,
                                        location_tag: card.location_tag,
                                        partnumber: card.partnumber,
                                        description: card.description,
                                        metric_unit: card.metric_unit,
                                        hts: card.hts,
                                        files: []
                                    }}
                                    url="update_immex_partnumber" /> :
                                null
                        }
                        {
                            showImage ?
                                <PartViewer
                                    handleForm={setShowImage}
                                    id={card.immex_partnumber_id}
                                    url="get_partnumber_image_immex" /> :
                                null
                        }
                        <DeleteCard
                            show={showDelete}
                            handleForm={setShowDelete}
                            id={card.immex_partnumber_id}
                            dashboardRequest={dashboardRequest}
                            name="ImmexPartnumber" />
                        {
                            showCheckout ?
                                <AddImmexCheckout
                                    partnumber={card.partnumber}
                                    location_tag={!card.location_tag?'None':card.location_tag}
                                    userInfo={userInfo}
                                    partnumber_id={card.immex_partnumber_id}
                                    available_qty={card.available_qty}
                                    handleForm={setShowCheckout}
                                    dashboardRequest={dashboardRequest} /> : null
                        }
        </div>
    );
}