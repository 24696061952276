import "../assets/global.css";
import axios from "axios";
import useAuth from "../hooks/useAuth";
import { useNavigate, Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Login() {
	//const { login } = useAuth();
	const { setAuth } = useAuth();
	const navigate = useNavigate();

	const userPermission = (roles, key)=>{
		if("permissions" in roles){
			if( key in roles.permissions){
				return roles.permissions[key] === "admin";
			}
			else return false;
		}
		else return false;
	}
	const userPermissionNoBoolean = (roles, key)=>{
		if("permissions" in roles){
			if( key in roles.permissions){
				return roles.permissions[key];
			}
			else return "user";
		}
		else return "user";
	}

	const handleLogin = async event => {
		event.preventDefault();

		const userEmail = event.target.userEmail.value;
		const userPassword = event.target.userPassword.value;
		const endpoint = process.env.REACT_APP_API_URL + "login";

		try {
			const response = await axios.post(
				endpoint,
				{
					email: userEmail,
					password: userPassword,
				},
				{
					headers: {
						"Content-Type": "application/json",
					},
				}
			);

			if (response.data.userType === "user") {
				//history("/comunidades");
			} else if (response.data.userType === "admin") {
				//history("/dashboard");
			}

			setAuth(response.data);
			localStorage.setItem("userName", response.data.userName);
			localStorage.setItem("userEmail", response.data.email);
			localStorage.setItem("userId", response.data.userId);
			localStorage.setItem("userType", response.data.roles.userType);
			localStorage.setItem("expenseAdmin", userPermission(response.data.roles, "expenses"));
			localStorage.setItem("userviewerAdmin", userPermission(response.data.roles, "userViewer"));
			localStorage.setItem("userImmexRole", userPermissionNoBoolean(response.data.roles, "immex"));
			localStorage.setItem("organizationId", response.data.organization_id);

			if (!localStorage.getItem("theme")) {
				localStorage.setItem("theme", "light");
			}
			navigate("/dashboard", { replace: true });
		} catch (error) {
			console.log(error);
			toast.error(error.response.data.msg);
		}
	};

	return (
		<div className="lg:flex bg-white h-screen	">
			<div className="lg:w-1/2 xl:max-w-screen-sm">
				<div className="py-12 bg-indigo-100 lg:bg-white flex lg:justify-center">
					<img src="/images/pacLogoBlack.svg" width="250" height="125" />
				</div>
				<div className="mt-10 px-12 sm:px-24 md:px-48 lg:px-12 lg:mt-16 xl:px-24 xl:max-w-2xl">
					<h2
						className="text-center text-4xl text-gray-800 font-display font-semibold lg:text-left xl:text-5xl
                    xl:text-bold"
					>
						PAC Mexico Log In
					</h2>
					<div className="mt-12">
						<form onSubmit={handleLogin} action="/schedule">
							<div>
								<div className="text-sm font-bold text-gray-700 tracking-wide">Email Address</div>
								<input
									name="userEmail"
									className="w-full text-lg py-2 border-b bg-white text-black border-gray-300 focus:outline-none focus:border-indigo-500 pl-2"
									type="text"
									required
									placeholder="name@pacmexico.com.mx"
								/>
							</div>
							<div className="mt-8">
								<div className="flex justify-between items-center">
									<div className="text-sm font-bold text-gray-700 tracking-wide">Password</div>
									<div>
										<a
											className="text-xs font-display font-semibold text-indigo-600 hover:text-indigo-800
                                        cursor-pointer"
										>
											Forgot Password?
										</a>
									</div>
								</div>
								<input
									name="userPassword"
									className="w-full text-lg py-2 border-b bg-white text-black border-gray-300 focus:outline-none focus:border-indigo-500 focus:bg-white pl-2"
									type="password"
									required
									placeholder="Enter your password"
								/>
							</div>
							<div className="mt-10">
								<button
									className="bg-blue-800 text-gray-100 p-4 w-full rounded-full tracking-wide
                                font-semibold font-display focus:outline-none focus:shadow-outline hover:bg-indigo-600
                                shadow-lg"
								>
									Log In
								</button>
							</div>
						</form>
						<div className="mt-12 text-sm font-display font-semibold text-gray-700 text-center">
							Don't have an account ?{" "}
							<Link to="/signup" className="cursor-pointer text-indigo-600 hover:text-indigo-800">Sign up</Link>
						</div>
					</div>
				</div>
			</div>
			<div className="hidden lg:flex items-center justify-center bg-indigo-100 flex-1 h-screen shadow-inner">
				<div className="max-w-xs transform duration-200 hover:scale-110 cursor-pointer">
					<img src="/images/pacLogoBlack.svg" width="500" height="250" />
				</div>
			</div>
			<ToastContainer
				position="bottom-right"
				autoClose={5000}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
			/>
		</div>
	);
}

export default Login;
