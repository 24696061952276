import { useEffect, useState } from "react";
import PartnumberTable from "../../components/tables/PartnumberTable";
import useFetch from "../../hooks/useFetch";
import AddAndUpdateImmexPartnumber from "../../components/form/AddAndUpdateImmexPartnumber";
import HeadStatsImmexPartnumber from "../../components/ui/HeadStatsImmexPartnumber";
import BarImmexPartnumbers from "../../components/ui/BarImmexPartnumbers";
import GridImmexPartnumbers from "../../components/tables/GridImmexPartnumbers";
import { getUserData } from "../../utils/utils";

const getSumList = (data, key) => {
    const bomCost = data.reduce((total, row) => {
        return total + parseFloat(row[key])
    }, 0)

    return bomCost;
}

export default function ImmexPartnumbers() {
    const [showEdit, setShowEdit] = useState(false);
    const [pn, setPn] = useState("");
    const [gridOrList, setGridOrList] = useState(false);

    const userInfo = getUserData();

    const dataTable = useFetch(`${process.env.REACT_APP_API_URL}get_immexpartnumbers`, { partnumber: pn })
    useEffect(() => {
        dataTable.handleRequest()
    }, [])
    return (
        <div className="w-full">
            <HeadStatsImmexPartnumber qty={getSumList(dataTable.data, "stock_qty")} pns={dataTable.data.length} />
            <BarImmexPartnumbers refreshPage={dataTable.handleRequest}
                gridOrList={gridOrList}
                setGridOrList={setGridOrList}
                setPn={setPn}
                pn={pn}
                setShowEdit={setShowEdit} />
            {dataTable.loading?
                <progress className="progress w-full"></progress>:null}
            {
                !gridOrList ?

                    <GridImmexPartnumbers getPartnumbers={dataTable} userInfo={userInfo} /> :
                    <PartnumberTable
                        userInfo={userInfo}
                        data={dataTable.data}
                        dashboardRequest={dataTable.handleRequest}
                        loading={dataTable.loading} />
            }

            {
                showEdit ?
                    <AddAndUpdateImmexPartnumber
                        handleForm={setShowEdit}
                        dashboardRequest={dataTable.handleRequest}
                        url="add_immex_partnumber"
                        title="Add Partnumber" /> :
                    null
            }
        </div>
    );
}

