

export default function HeadStatsCheckout({checkouts, process, complete}) {
    return (
        <div className="lg:flex lg:justify-center mt-2 w-full">
            <div className="grid lg:grid-flow-col lg:auto-cols-auto gap-4 grid-flow-row auto-rows-max">
                <div className="stat bg-base-100 rounded-xl">
                    <div className="stat-figure text-blue-500">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" className="inline-block w-8 h-8 stroke-current"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 4h3a1 1 0 0 1 1 1v15a1 1 0 0 1-1 1H6a1 1 0 0 1-1-1V5a1 1 0 0 1 1-1h3m0 3h6m-5-4v4h4V3h-4Z"></path></svg>
                    </div>
                    <div className="stat-title">Checkouts</div>
                    <div className="stat-value text-blue-400 text-2xl pt-2">{checkouts}</div>
                </div>
                <div className="stat bg-base-100 rounded-xl">
                    <div className="stat-figure text-amber-500">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" className="inline-block w-8 h-8 stroke-current"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 8v4l3 3m6-3a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"></path></svg>
                    </div>

                    <div className="stat-title">In Process</div>
                    <div className="stat-value text-amber-400 text-2xl pt-2">{process}</div>
                </div>

                <div className="stat bg-base-100 rounded-xl">
                    <div className="stat-figure text-green-500">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" className="inline-block w-8 h-8 stroke-current"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 4h3a1 1 0 0 1 1 1v15a1 1 0 0 1-1 1H6a1 1 0 0 1-1-1V5a1 1 0 0 1 1-1h3m0 3h6m-5-4v4h4V3h-4Z"></path></svg>
                    </div>
                    <div className="stat-title">Completed</div>
                    <div className="stat-value text-green-400 text-2xl pt-2">{complete}</div>
                </div>
            </div>

        </div>
    );
}