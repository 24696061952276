import { useState } from "react"
import useFetchClick from "../../hooks/useFetchClick"
import SteperSimple from "../ui/SteperSimple"
import DeleteCard from "../form/DeleteCard"

const selectButton = (data) => {
    if (data.pickup_datetime) {
        return 'Delivery'
    }
    else if (data.authorize_datetime) {
        return 'Pickup'
    }
    else if (data.create_datetime) {
        return 'Authorize'
    }

    else {
        return ''
    }
}

export default function CheckoutCard({ card, dashboardRequest, userInfo }) {
    const [showDel, setShowDel] = useState(false);

    const nextState = selectButton(card);
    const submitState = useFetchClick(
        `${process.env.REACT_APP_API_URL}set_checkout_${nextState}`,
        {
            userId:userInfo.userId,
            id: card.immex_checkout_id,
            partnumber:card.partnumber,
            qty:card.qty,
            location_tag:card.location_tag,
            client:card.client
        }, dashboardRequest);
    console.log(userInfo)
    return (
        <div className="w-full">
            <div className={!card.delivery_datetime ? `flex bg-yellow-500 rounded-lg drop-shadow-lg z-0` : `flex bg-green-500 rounded-lg drop-shadow-lg z-0`}>
                <div className="flex-none w-5" />
                <div className="grow bg-base-100  p-4">
                    <div className="text-lg lg:text-2xl text-center ">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" className="inline-block w-6 h-6 stroke-current mr-1 mb-1 text-white bg-fuchsia-600 rounded-md"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 10V6a3 3 0 0 1 3-3v0a3 3 0 0 1 3 3v4m3-2 .917 11.923A1 1 0 0 1 17.92 21H6.08a1 1 0 0 1-.997-1.077L6 8h12Z"></path></svg>

                        {card.partnumber}
                    </div>
                        <div className="basis-1/3  text-base lg:text-xl">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" className="inline-block w-6 h-6 stroke-current mr-1 mb-1 text-white bg-green-600 rounded-md">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 20a7.966 7.966 0 0 1-5.002-1.756l.002.001v-.683c0-1.794 1.492-3.25 3.333-3.25h3.334c1.84 0 3.333 1.456 3.333 3.25v.683A7.966 7.966 0 0 1 12 20ZM2 12C2 6.477 6.477 2 12 2s10 4.477 10 10c0 5.5-4.44 9.963-9.932 10h-.138C6.438 21.962 2 17.5 2 12Zm10-5c-1.84 0-3.333 1.455-3.333 3.25S10.159 13.5 12 13.5c1.84 0 3.333-1.455 3.333-3.25S13.841 7 12 7Z"></path>
                            </svg>
                            {card.name}
                        </div>
                        <div className="basis-1/6  text-base lg:text-xl">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" className="inline-block w-6 h-6 stroke-current mr-1 mb-1 text-white bg-blue-600 rounded-md">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 3a1 1 0 0 0 0 2h.687L7.82 15.24A3 3 0 1 0 11.83 17h2.34A3 3 0 1 0 17 15H9.813l-.208-1h8.145a1 1 0 0 0 .979-.796l1.25-6A1 1 0 0 0 19 6h-2.268A2 2 0 0 1 15 9a2 2 0 1 1-4 0 2 2 0 0 1-1.732-3h-1.33L7.48 3.796A1 1 0 0 0 6.5 3H5Z"></path>
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M14 5a1 1 0 1 0-2 0v1h-1a1 1 0 1 0 0 2h1v1a1 1 0 1 0 2 0V8h1a1 1 0 1 0 0-2h-1V5Z"></path>
                            </svg>
                            {card.qty}
                        </div>
                        <div className="basis-1/2  text-base lg:text-xl">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" className="inline-block w-6 h-6 stroke-current mr-1 mb-1 text-white bg-gray-600 rounded-md">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm11-4a1 1 0 1 0-2 0v4a1 1 0 0 0 .293.707l3 3a1 1 0 0 0 1.414-1.414L13 11.586V8Z"></path>
                            </svg>
                            {new Date(card.create_datetime).toLocaleString()}
                        </div>
                    <div className="basis-1/3  text-base lg:text-xl">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" className="inline-block w-6 h-6 stroke-current mr-1 mb-1 text-white bg-green-600 rounded-md">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 13a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z"></path>
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M17.8 13.938h-.011a7 7 0 1 0-11.464.144h-.016l.14.171c.1.127.2.251.3.371L12 21l5.13-6.248c.194-.209.374-.429.54-.659l.13-.155ZZ"></path>
                                                </svg>
                        {card.location_tag}
                    </div>
                    <div className="basis-1/3  text-base lg:text-xl">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" className="inline-block w-6 h-6 stroke-current mr-1 mb-1 text-white bg-green-600 rounded-md">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 4h12M6 4v16M6 4H5m13 0v16m0-16h1m-1 16H6m12 0h1M6 20H5M9 7h1v1H9V7Zm5 0h1v1h-1V7Zm-5 4h1v1H9v-1Zm5 0h1v1h-1v-1Zm-3 4h2a1 1 0 0 1 1 1v4h-4v-4a1 1 0 0 1 1-1Z"></path>
                        </svg>
                        {card.client}
                    </div>
                    <SteperSimple data={card} />
                    {!card.delivery_datetime ?
                        <div className="flex">
                            {
                                userInfo.userImmexRole === "admin" || nextState !== "Authorize" ?
                                    <button
                                        onClick={() => submitState.handleRequest()}
                                        className={(userInfo.userImmexRole !== "admin" || nextState === "Authorize") ? "btn btn-square btn-success mr-4 mt-4 flex-grow":"btn btn-square btn-success mr-4 mt-4"}>
                                        <svg xmlns="http://www.w3.org/2000/svg" className={(userInfo.userImmexRole !== "admin" || nextState === "Authorize") ? "h-6 w-6 ml-1" : "h-6 w-6"} fill="none" viewBox="0 0 24 24" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm13.707-1.293a1 1 0 0 0-1.414-1.414L11 12.586l-1.793-1.793a1 1 0 0 0-1.414 1.414l2.5 2.5a1 1 0 0 0 1.414 0l4-4Z" /></svg>
                                        {(userInfo.userImmexRole !== "admin" || nextState === "Authorize") ? nextState : null}
                                    </button> : null
                            }
                            {/*
                                userInfo.userImmexRole === "admin" || nextState === "Authorize" ?
                                    <button
                                        className="btn btn-square btn-info mr-4 mt-4">
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 ml-1" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m14.304 4.844 2.852 2.852M7 7H4a1 1 0 0 0-1 1v10a1 1 0 0 0 1 1h11a1 1 0 0 0 1-1v-4.5m2.409-9.91a2.017 2.017 0 0 1 0 2.853l-6.844 6.844L8 14l.713-3.565 6.844-6.844a2.015 2.015 0 0 1 2.852 0Z" /></svg>
                                    </button> : null
                            */}
                            {
                                userInfo.userImmexRole === "admin" || nextState === "Authorize" ?
                                    <button
                                        onClick={() => setShowDel(true)}
                                        className="btn btn-square btn-error mt-4">
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 7h14m-9 3v8m4-8v8M10 3h4a1 1 0 0 1 1 1v3H9V4a1 1 0 0 1 1-1ZM6 7h12v13a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V7Z" /></svg>
                                    </button> : null
                            }
                        </div> : null
                    }
                </div>

                <div className="flex-none w-5 " />
            </div>
            <DeleteCard
                show={showDel}
                handleForm={setShowDel}
                id={card.immex_checkout_id}
                dashboardRequest={dashboardRequest}
                name="ImmexCheckout" />
        </div>
    );
}