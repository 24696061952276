import { useEffect } from "react";
import PedimentoRow from "./PedimentoRow";

export default function PedimentoTable({ changeTableById, refresh, pedimentosTable }) {
    
    useEffect(() => {
        pedimentosTable.handleRequest();
    }, [refresh])

    return (
        <div className="w-full p-4 rounded-lg border-4 border-gray-600">
            <h1 className="text-xl font-bold pb-2">
                Pedimentos
                { pedimentosTable.loading?
                    <span className="loading loading-spinner loading-sm ml-2"/>:
                    null
                }
            </h1>
            <div className="overflow-x-auto border-solid border-2 border-black rounded-lg">
                <table className="table table-xs table-zebra">
                    <thead className="border-b-2 border-black">
                        <tr>
                            <th>Pedimento</th>
                            <th>Import Date</th>
                            <th>Months Passed</th>
                            <th>Date Status</th>
                            <th>Required Export Date</th>
                            <th>Export Date</th>
                            <th>Stock Status</th>
                            <th>Partidas</th>
                            <th>Partnumbers</th>
                            <th>Package Qty</th>
                            <th>Package Price</th>
                            <th>Stock Qty</th>
                            <th>Stock Price</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            pedimentosTable.data.map((row, ind) =>
                                <PedimentoRow
                                    changeTableById={changeTableById}
                                    row={row}
                                    ind={ind}
                                    dashboardRequest={pedimentosTable.handleRequest} />
                            )
                        }
                    </tbody>
                </table>
            </div>
        </div>
    );
}