import useFetchGet from "../../hooks/useFetchGet";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faCalendarDays,
	faChevronDown,
	faUserShield,
	faUser,
	faHouse,
} from "@fortawesome/free-solid-svg-icons";
import Select from "react-select";

function EditJigForm(props) {
	const { row } = props;
	const [isActive, setIsActive] = useState(false);

	/*const options = [
		{ value: "chocolate", label: "Chocolate", custom: "dsa" },
		{ value: "strawberry", label: "Strawberry" },
		{ value: "vanilla", label: "Vanilla" },
	];
	const [selectedOption, setSelectedOption] = useState([]);
	changeTab(selectedOption);

	console.log(selectedOption);

	var currentArea = "";
	const endpoint = process.env.REACT_APP_API_URL + "clientsForSelect";
	const { data, loading, error, refetch } = useFetchGet(endpoint, {
		appName: "schedule",
	});
	//console.log(data);

	if (loading) return <h1>LOADING...</h1>;

	if (error) console.error(error);*/

	return (
		<form>
			<div className="form-control w-full ">
				<label className="label">
					<span className="label-text">Id</span>
				</label>
				<input
					type="text"
					className="input input-bordered w-full"
					name="id"
                    id="id"
					disabled
                    defaultValue={row.id}
				/>
			</div>

            <div className="form-control w-full ">
				<label className="label">
					<span className="label-text">Row</span>
				</label>
				<input
					type="text"
					className="input input-bordered w-full"
					name="row"
                    id="row"
                    defaultValue={row.row}
				/>
			</div>

            <div className="form-control w-full ">
				<label className="label">
					<span className="label-text">Position</span>
				</label>
				<input
					type="text"
					className="input input-bordered w-full"
					name="position"
                    id="position"
                    defaultValue={row.position}
				/>
			</div>

            <div className="form-control w-full ">
				<label className="label">
					<span className="label-text">Quantity</span>
				</label>
				<input
					type="text"
					className="input input-bordered w-full"
					name="quantity"
                    id="quantity"
                    defaultValue={row.quantity}
				/>
			</div>

            <div className="form-control w-full ">
				<label className="label">
					<span className="label-text">Name</span>
				</label>
				<input
					type="text"
					className="input input-bordered w-full"
					name="name"
                    id="name"
                    defaultValue={row.name}
				/>
			</div>

            <div className="form-control w-full ">
				<label className="label">
					<span className="label-text">Description</span>
				</label>
				<input
					type="text"
					className="input input-bordered w-full"
					name="description"
                    id="description"
                    defaultValue={row.description}
				/>
			</div>

            <div className="form-control w-full ">
				<label className="label">
					<span className="label-text">Location</span>
				</label>
				<input
					type="text"
					className="input input-bordered w-full"
					name="location"
                    id="location"
                    defaultValue={row.location}
				/>
			</div>


		</form>
	);
}

export default EditJigForm;
