import { useState, useEffect } from "react";
import useFetch from "../../hooks/useFetch";
import GridCheckout from "../../components/tables/GridCheckout";
import HeadStatsCheckout from "../../components/ui/HeadStatsCheckout";
import BarImmexCheckout from "../../components/ui/BarImmexCheckout";
import { getUserData } from "../../utils/utils";

const sumAuthorized = (data, prop) => {
    return data.filter((row) => row[prop] ? true : false).length
}

export default function ImmexCheckout() {
    const userInfo = getUserData();
    const getCheckouts = useFetch(`${process.env.REACT_APP_API_URL}get_getckouts`, {
        isAdmin : userInfo.userImmexRole === "admin",
        userId:userInfo.userId,
    })
    useEffect(() => {
        getCheckouts.handleRequest();
    }, [])
    console.log(userInfo)
    return (
        <div className="w-full">
            <HeadStatsCheckout 
                checkouts={getCheckouts.data.length}
                process={getCheckouts.data.length - sumAuthorized(getCheckouts.data, "delivery_datetime")}
                complete={sumAuthorized(getCheckouts.data, "delivery_datetime")}/>
            <BarImmexCheckout refreshPage={getCheckouts.handleRequest}/>
            {getCheckouts.loading?
                <progress className="progress w-full"></progress>:null}
            <GridCheckout 
                getCheckouts={getCheckouts} 
                dashboardRequest={getCheckouts.handleRequest}
                userInfo={userInfo} />
        </div>
    );
}