const StepTypes = {
    "emphy": "step",
    "wait": "step step-warning",
    "success": "step step-success",
}

//new Date(card.create_datetime).toLocaleString()
const datetimeTitle = (title, dt) => {
    if (dt) {
        //const dtReduce = dt.slice(0, 19);
        const dtReduce = new Date(dt).toLocaleString()
        const dtDivide = dtReduce.split(' ');
        return <h3 className="text-gray-300">
            {title}
            <p className="text-xs bg-cyan-400 text-gray-800 rounded-md">
                {dtDivide[0]}<br />
                {dtDivide[1] + ' ' + dtDivide[2]}
            </p>
        </h3>
    }
    else{
        return <h3 className="text-gray-300">
        {title}
    </h3>
    }

}

export default function SteperSimple({ data }) {
    return (
        <ul className="steps w-full bg-gray-800 p-2 rounded-lg">
            <li className={data.create_datetime ? StepTypes[data.delivery_datetime ? "success" : "wait"] : "step"}>
                {datetimeTitle("Create", data.create_datetime)}
            </li>
            <li className={data.authorize_datetime ? StepTypes[data.delivery_datetime ? "success" : "wait"] : "step"}>
                {datetimeTitle("Authorize", data.authorize_datetime)}
            </li>
            <li className={data.pickup_datetime ? StepTypes[data.delivery_datetime ? "success" : "wait"] : "step"}>
                {datetimeTitle("Pickup", data.pickup_datetime)}
            </li>
            <li className={data.delivery_datetime ? StepTypes[data.delivery_datetime ? "success" : "wait"] : "step"}>
                {datetimeTitle("Delivery", data.delivery_datetime)}
            </li>
        </ul>
    );
}